<template>
  <div class="card border-0 shadow">
    <div class="card-header fw-bold">
      {{ $t('billing.billingManagement.billingOverview.header', { supplier, month: pickedMonth }) }}
    </div>
    <div v-loading="loading" class="card-body pt-4">
      <div class="d-flex align-items-center">
        <div class="flex-grow-1">
          <p class="d-flex justify-content-between fw-bold">
            <span>
              {{ $t('billing.billingManagement.billingOverview.total', { count: invoicesTotal.count }) }}
            </span>
            <span> {{ formatCentsToCurrency(invoicesTotal.total) ?? '-' }} </span>
          </p>
          <hr class="my-1" />
          <OverviewItem :type="OVERVIEW_DATA_TYPE.APPROVED" :count="approved.count" :total="approved.total" divider />
          <OverviewItem
            :type="OVERVIEW_DATA_TYPE.NOT_APPROVED"
            :count="notApproved.count"
            :total="notApproved.total"
            divider
          />
          <OverviewItem :type="OVERVIEW_DATA_TYPE.MISSING" :count="missing.count" :total="missing.total" />
        </div>
        <div class="mx-2" />
        <div class="position-relative">
          <DoughnutChart
            :chunks="chartChunks"
            :height="156"
            :width="156"
            @tooltip-visible="doughnutInnerTextVisible = !$event"
          />
          <div
            v-if="doughnutInnerTextVisible"
            class="position-absolute w-100 position-absolute text-center lh-1"
            style="bottom: 38%"
          >
            <p class="fw-bold">
              {{ formatCentsToCurrency(invoicesTotal.total) ?? '-' }}
            </p>
            <small class="text-muted">
              {{ $t('billing.billingManagement.billingOverview.chart.invoiceCount', { count: invoicesTotal.count }) }}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isNil } from 'ramda';
import { INVOICE_DOCS, CONSOLIDATED_DOCS } from '@/modules/document/types';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import OverviewItem, { OVERVIEW_DATA_TYPE } from './OverviewItem';
import DoughnutChart from './DoughnutChart';

const getSumsForInvoices = (billings) => ({
  count: billings.length,
  total:
    billings.length && billings.every((billing) => !isNil(billing.totalAmount))
      ? billings.reduce((acc, invoice) => acc + invoice.totalAmount, 0)
      : null,
});

export default {
  components: { DoughnutChart, OverviewItem },
  props: {
    loading: { type: Boolean, default: false },
    billings: { type: Array, required: true },
    missingEvents: { type: Array, required: true },
    supplier: { type: String, required: true },
    month: { type: String, required: true },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency({ maximumFractionDigits: 0 });

    return { formatCentsToCurrency };
  },
  data() {
    return {
      OVERVIEW_DATA_TYPE,
      doughnutInnerTextVisible: true,
    };
  },
  computed: {
    missingInvoices() {
      return this.missingEvents
        .filter((missingEvent) =>
          missingEvent.possibleTypes.some((type) => [...CONSOLIDATED_DOCS, ...INVOICE_DOCS].includes(type))
        )
        .map(({ context }) => ({
          totalAmount: context.calculatedAmount ?? 0,
        }));
    },
    invoicesTotal() {
      return getSumsForInvoices([...this.billings, ...this.missingInvoices]);
    },
    approved() {
      const approved = this.billings.filter((billing) => !billing.imbalances.length);
      return getSumsForInvoices(approved);
    },
    notApproved() {
      const notApproved = this.billings.filter((billing) => billing.imbalances.length);
      return getSumsForInvoices(notApproved);
    },
    missing() {
      return getSumsForInvoices(this.missingInvoices);
    },
    pickedMonth() {
      return new Date(this.month).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
    chartChunks() {
      return this.billings.length
        ? [
            { label: OVERVIEW_DATA_TYPE.APPROVED, amount: this.approved.total, count: this.approved.count },
            { label: OVERVIEW_DATA_TYPE.NOT_APPROVED, amount: this.notApproved.total, count: this.notApproved.count },
            { label: OVERVIEW_DATA_TYPE.MISSING, amount: this.missing.total, count: this.missing.count },
          ]
        : [];
    },
  },
};
</script>
