import { Doughnut } from 'vue-chartjs';
import colors from '@/stylesheets/scss/colors.module.scss';
import { percent } from '@/locale/numberConfig';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  extends: Doughnut,
  props: {
    chunks: { type: Array, required: true },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency({ maximumFractionDigits: 0 });

    return { formatCentsToCurrency };
  },
  watch: {
    chunks: {
      deep: true,
      handler(newChunks) {
        if (newChunks.length) {
          this.$data._chart.data.datasets[0].data = newChunks.map((chunk) => chunk?.amount ?? 0);
          this.$data._chart.data.datasets[0].backgroundColor = [colors.success, colors.error, colors.warning];
        } else {
          this.$data._chart.data.datasets[0].data = [100];
          this.$data._chart.data.datasets[0].backgroundColor = [colors.outline];
        }
        this.$data._chart.update();
      },
    },
  },
  methods: {
    formatPercent(number) {
      return typeof number === 'number' && !Number.isNaN(number)
        ? Number(number / 100).toLocaleString(this.$i18n.locale, percent)
        : null;
    },
  },
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            data: this.chunks.length ? this.chunks.map((chunk) => chunk?.amount ?? 0) : [100],
            backgroundColor: this.chunks.length ? [colors.success, colors.error, colors.warning] : [colors.outline],
            hoverBorderWidth: [0, 0],
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: 80,
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 10,
          },
        },
        tooltips: {
          display: true,
          displayColors: false,
          bodyAlign: this.$direction === 'rtl' ? 'right' : 'left',
          custom: (tooltip) => {
            if (tooltip.opacity > 0) {
              this.$emit('tooltip-visible', true);
            } else {
              this.$emit('tooltip-visible', false);
            }
          },
          callbacks: {
            title: (tooltipItem) => {
              if (this.chunks.length) {
                const item = this.chunks[tooltipItem[0].index];
                return this.$t(`billing.billingManagement.billingOverview.chart.${item.label}`, {
                  amount: this.formatCentsToCurrency(item.amount),
                });
              }
              return this.$t('billing.billingManagement.billingOverview.chart.noInvoices');
            },

            label: (tooltipItem) => {
              if (this.chunks.length) {
                return this.$t('billing.billingManagement.billingOverview.chart.invoiceCount', {
                  count: this.chunks[tooltipItem.index].count,
                });
              }
              return null;
            },
          },
        },
      }
    );
  },
};
